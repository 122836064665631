import scrollama from "scrollama";
import { useEffect } from "react";

/**
 * useScrollama: an easier usage of scrollama as a react hook
 *
 *    useScrollama(titleRef, {
 *      offset: 0.75,
 *      // debug: true,
 *      onStepProgress: (response) => {
 *        const { progress } = response;
 *        setOverlayLineHeight(getLineHeight(progress));
 *      },
 *    });
 *
 * @param  {Object} ref     a react ref of the element you want to track
 * @param  {Object} options options from scrollama plus step functions and offsetTrigger
 *   see https://github.com/russellgoldenberg/scrollama#scrollamasetupoptions
 */
export default function useScrollama(ref, options) {
  useEffect(() => {
    if (ref.current) {
      const {
        onStepEnter = () => {},
        onStepExit = () => {},
        onStepProgress = () => {},
        offsetTrigger = null,
      } = options;

      const scroller = scrollama();

      scroller
        .setup({
          step: ref.current,
          progress: options.onStepProgress ? true : false,
          ...options,
        })
        .onStepEnter(onStepEnter)
        .onStepExit(onStepExit)
        .onStepProgress(onStepProgress)
        .offsetTrigger(offsetTrigger);

      window.addEventListener("resize", scroller.resize);
      window.addEventListener("load", scroller.resize); // after images load, recalculate

      return () => {
        window.removeEventListener("resize", scroller.resize);
        window.removeEventListener("load", scroller.resize);
      };
    }
    // we only want to set this up once!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
